


















import LazyHydrate from "vue-lazy-hydration";
import { useRoute, defineComponent, useAsync } from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";
import AppHeader from "~/components/AppHeader.vue";
import IconSprite from "~/components/General/IconSprite.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import TopBar from "~/components/TopBar/TopBar.vue";
import { useCart } from "~/modules/checkout/composables/useCart";

export default defineComponent({
  name: "DefaultLayout",

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    IconSprite,
    TopBar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ "~/components/AppFooter.vue"),
    WishlistSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/wishlist/components/WishlistSidebar.vue"
      ),
    LoginModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"
      ),
    Notification: () =>
      import(/* webpackPrefetch: true */ "~/components/Notification.vue"),
  },

  middleware({ app, redirect }) {
    if (app.context.route.query.token) {
      app.context.$cookies.remove("vsf-customer");
      app.context.$cookies.remove("vsf-cart");
      app.context.$cookies.remove("sale-person");
      app.context.$cookies.set("vsf-customer", app.context.route.query.token);
      app.context.$cookies.set(
        "sale-person",
        app.context.route.query.salesperson_session
      );
      return redirect("/");
    }
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    const { load: loadCart } = useCart();

    useAsync(async () => {
      await loadCart();
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  },
  head: {
    link: [{ rel: "stylesheet", href: "/_nuxt/fonts.css" }],
  },
  mounted() {
    // Get the header
    const header = document.getElementById("myHeader");
    const mainContent = document.getElementById("layout");
    const addStickyHeader = () => {
      const scrollPosition =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollPosition > sticky) {
        header.classList.add("stickyHeader");
        mainContent.classList.add("stickyLayout");
      } else {
        header.classList.remove("stickyHeader");
        mainContent.classList.remove("stickyLayout");
      }
    };
    window.onscroll = function () {
      addStickyHeader();
    };

    // Get the offset position of the navbar
    const sticky = header.offsetTop;
  },
});
