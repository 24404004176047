import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _690670fd = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _4fa77d5d = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "pages/AboutUs" */))
const _30a8b8ee = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _3beda22f = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _304b8c7b = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _620a42f4 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _18e0395e = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _026e0505 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _013b1662 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _421e44ca = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _0fe18ff2 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _7ffdbcd2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _597fe380 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _c254cc7a = () => interopDefault(import('../modules/customer/pages/MyAccount/FastOrder.vue' /* webpackChunkName: "" */))
const _b50f68f6 = () => interopDefault(import('../modules/customer/pages/MyAccount/Invoices.vue' /* webpackChunkName: "" */))
const _259d0560 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyDownloads.vue' /* webpackChunkName: "" */))
const _4828ad24 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _37534abc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _c3110080 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _2fad70f0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _694cc480 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _d43ff9e8 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _fbba640a = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _36256f4e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1538de73 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _690d38e8 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _f4dcf808 = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _8d88f2a6 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _3cfb35c1 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))
const _c3819426 = () => interopDefault(import('../pages/Storyblok.vue' /* webpackChunkName: "pages/Storyblok" */))
const _1e09522e = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/fr",
    component: _690670fd,
    name: "home___fr"
  }, {
    path: "/nl",
    component: _690670fd,
    name: "home___nl"
  }, {
    path: "/fr/AboutUs",
    component: _4fa77d5d,
    name: "AboutUs___fr"
  }, {
    path: "/fr/cart",
    component: _30a8b8ee,
    name: "cart___fr"
  }, {
    path: "/fr/checkout",
    component: _3beda22f,
    name: "checkout___fr",
    children: [{
      path: "billing",
      component: _304b8c7b,
      name: "billing___fr"
    }, {
      path: "payment",
      component: _620a42f4,
      name: "payment___fr"
    }, {
      path: "shipping",
      component: _18e0395e,
      name: "shipping___fr"
    }, {
      path: "thank-you",
      component: _026e0505,
      name: "thank-you-checkout___fr"
    }, {
      path: "user-account",
      component: _013b1662,
      name: "user-account___fr"
    }]
  }, {
    path: "/fr/Cms",
    component: _421e44ca,
    name: "Cms___fr"
  }, {
    path: "/fr/Contact",
    component: _0fe18ff2,
    name: "Contact___fr"
  }, {
    path: "/fr/customer",
    component: _7ffdbcd2,
    meta: {"titleLabel":"My Account"},
    name: "customer___fr",
    children: [{
      path: "addresses-details",
      component: _597fe380,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___fr"
    }, {
      path: "fast-order",
      component: _c254cc7a,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___fr"
    }, {
      path: "invoices",
      component: _b50f68f6,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___fr"
    }, {
      path: "my-downloads",
      component: _259d0560,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___fr"
    }, {
      path: "my-newsletter",
      component: _4828ad24,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___fr"
    }, {
      path: "my-profile",
      component: _37534abc,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___fr"
    }, {
      path: "my-reviews",
      component: _c3110080,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___fr"
    }, {
      path: "my-wishlist",
      component: _2fad70f0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___fr"
    }, {
      path: "order-history",
      component: _694cc480,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___fr"
    }, {
      path: "addresses-details/create",
      component: _d43ff9e8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___fr"
    }, {
      path: "/fr/reset-password",
      component: _fbba640a,
      alias: "/customer/account/createPassword",
      name: "reset-password___fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _36256f4e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___fr"
    }, {
      path: "order-history/:orderId",
      component: _1538de73,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___fr"
    }]
  }, {
    path: "/fr/Faq",
    component: _690d38e8,
    name: "Faq___fr"
  }, {
    path: "/fr/ForgotPassword",
    component: _f4dcf808,
    name: "ForgotPassword___fr"
  }, {
    path: "/fr/Home",
    component: _690670fd,
    name: "Home___fr"
  }, {
    path: "/fr/Page",
    component: _8d88f2a6,
    name: "Page___fr"
  }, {
    path: "/fr/Register",
    component: _3cfb35c1,
    name: "Register___fr"
  }, {
    path: "/fr/Storyblok",
    component: _c3819426,
    name: "Storyblok___fr"
  }, {
    path: "/fr/thank-you",
    component: _1e09522e,
    name: "thank-you___fr"
  }, {
    path: "/nl/AboutUs",
    component: _4fa77d5d,
    name: "AboutUs___nl"
  }, {
    path: "/nl/cart",
    component: _30a8b8ee,
    name: "cart___nl"
  }, {
    path: "/nl/checkout",
    component: _3beda22f,
    name: "checkout___nl",
    children: [{
      path: "billing",
      component: _304b8c7b,
      name: "billing___nl"
    }, {
      path: "payment",
      component: _620a42f4,
      name: "payment___nl"
    }, {
      path: "shipping",
      component: _18e0395e,
      name: "shipping___nl"
    }, {
      path: "thank-you",
      component: _026e0505,
      name: "thank-you-checkout___nl"
    }, {
      path: "user-account",
      component: _013b1662,
      name: "user-account___nl"
    }]
  }, {
    path: "/nl/Cms",
    component: _421e44ca,
    name: "Cms___nl"
  }, {
    path: "/nl/Contact",
    component: _0fe18ff2,
    name: "Contact___nl"
  }, {
    path: "/nl/customer",
    component: _7ffdbcd2,
    meta: {"titleLabel":"My Account"},
    name: "customer___nl",
    children: [{
      path: "addresses-details",
      component: _597fe380,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nl"
    }, {
      path: "fast-order",
      component: _c254cc7a,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___nl"
    }, {
      path: "invoices",
      component: _b50f68f6,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___nl"
    }, {
      path: "my-downloads",
      component: _259d0560,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___nl"
    }, {
      path: "my-newsletter",
      component: _4828ad24,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___nl"
    }, {
      path: "my-profile",
      component: _37534abc,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___nl"
    }, {
      path: "my-reviews",
      component: _c3110080,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___nl"
    }, {
      path: "my-wishlist",
      component: _2fad70f0,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___nl"
    }, {
      path: "order-history",
      component: _694cc480,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nl"
    }, {
      path: "addresses-details/create",
      component: _d43ff9e8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nl"
    }, {
      path: "/nl/reset-password",
      component: _fbba640a,
      alias: "/customer/account/createPassword",
      name: "reset-password___nl"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _36256f4e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nl"
    }, {
      path: "order-history/:orderId",
      component: _1538de73,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nl"
    }]
  }, {
    path: "/nl/Faq",
    component: _690d38e8,
    name: "Faq___nl"
  }, {
    path: "/nl/ForgotPassword",
    component: _f4dcf808,
    name: "ForgotPassword___nl"
  }, {
    path: "/nl/Home",
    component: _690670fd,
    name: "Home___nl"
  }, {
    path: "/nl/Page",
    component: _8d88f2a6,
    name: "Page___nl"
  }, {
    path: "/nl/Register",
    component: _3cfb35c1,
    name: "Register___nl"
  }, {
    path: "/nl/Storyblok",
    component: _c3819426,
    name: "Storyblok___nl"
  }, {
    path: "/nl/thank-you",
    component: _1e09522e,
    name: "thank-you___nl"
  }, {
    path: "/fr/:slug+",
    component: _8d88f2a6,
    name: "page___fr"
  }, {
    path: "/nl/:slug+",
    component: _8d88f2a6,
    name: "page___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
